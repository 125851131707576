<template>
<div id="vip-order">
  <div style="margin:40px 0 0 40px;text-align:left;">
    <h1 class="set-title">订单信息</h1>
  </div>

  <div style="margin: 20px 40px;">
    <el-table :data="orderList" style="width: 100%">
      <el-table-column prop="trade_no" label="订单号" width="200" />
      <el-table-column prop="description" label="描述" />
      <el-table-column prop="created_at" label="订单时间" />
      <el-table-column prop="trade_type" label="交易平台"  />
      <el-table-column prop="vip_pay_type" label="会员类型" />
    </el-table>
  </div>
</div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {apiGetVipOrderList} from "@/api/user/account";

const orderList = ref([])

onMounted(() => {
  apiGetVipOrderList().then(res => {
    if (res.data.err_no === 0) {
      orderList.value = res.data.results.list;
    }
  })
});
</script>

<style scoped>
.set-title {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.5rem;
}
</style>