<template>
<div id="vip-pay">
  <h1 class="fs-26">会员开通</h1>

  <div class="flex space-between mt-30">
    <el-card style="width:24%" shadow="hover" v-for="item in payList">
      <div class="flex flex-column flex-align-center">
        <div class="fw-500 fs-18">{{item.title}}</div>
        <div class="color-money mt-30">
          <span class="fs-14">￥</span><span class="fs-26 fw-600">{{parseFloat(item.price)}}</span>
        </div>
        <el-button type="primary" class="mt-30 w-80p" plain @click="vipPayOperate(item)">立即购买</el-button>
        <el-divider />
        <div class="flex flex-column text-left w-100p">
          <div class="fw-600 fs-18">会员权益</div>
          <div class="mt-20 fs-14 flex space-between" v-for="cItem in item.detail.item_list">
            <div>{{cItem.li_item}}</div>
            <div class="color-main">{{cItem?.li_value}}</div>
          </div>
        </div>
      </div>
    </el-card>
  </div>

<!--  <div class="mt-30 flex flex-center" style="border:1px solid #e4e7ed;padding:20px;border-radius:4px">-->
<!--    -->

<!--  </div>-->
</div>

  <el-dialog v-model="payDialogVisible"  width="500" center @close="closePayDialog">
    <div class="flex flex-center" style="padding:30px 10px;" v-loading="payLoading">
      <div>
        <qrcode-vue :value="qrcodeUrl"  level="H" />
      </div>
      <div class="ml-16">
        <div class="text-left fs-18 fw-600">{{payDesc}}</div>
        <div class="mt-20 flex flex-align-center">
          <span>使用微信</span>
          <img src="@/assets/img/wxIcon.png" style="width:20px;margin:0 10px;"/>
          <span>扫一扫，支付</span>
          <span class="color-money fw-600 fs-18" style="margin:0 10px;">{{ payOrderMoney }}</span>
          <span>元</span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {apiGetVipPay, apiGetUserVipPayCode, apiCheckPayStatus} from "@/api/user/account";
import QrcodeVue from 'qrcode.vue'
import router from "@/router";


const payList = ref([]);
onMounted(() => {
  apiGetVipPay().then(res => {
    if (res.data.err_no === 0) {
      payList.value = res.data.results;
    }
  })
});

const qrcodeUrl = ref("")
const payOrderMoney = ref("0");
const payDesc = ref("通言AI开通会员");
const selectedId = ref(0);
const payDialogVisible = ref(false);
const payTradeNo = ref("");
const payLoading = ref(false);
var payTimer;
const vipPayOperate = (item) => {
  payDialogVisible.value = true;
  payLoading.value = true;
  selectedId.value = item.id;
  apiGetUserVipPayCode({vip_id:item.id}).then(res => {
    if (res.data.err_no == 0) {
      payLoading.value = false;
      payTradeNo.value = res.data.results.trade_no;
      qrcodeUrl.value = res.data.results.code_url;
      payOrderMoney.value = res.data.results.order_money;
      payDesc.value = res.data.results.pay_desc;

      payTimer = setInterval(() => {
        apiCheckPayStatus({trade_no:payTradeNo.value}).then(res => {
          if (res.data.err_no == 0) {
            if (res.data.results === 'yes') {
              clearInterval(payTimer);

              //页面刷新跳转到成功付款页面
              router.push({  name: 'VipPayStatus'});
            }
          }
        })
      }, 6000);
    }
  })
}


const closePayDialog = () => {
  clearInterval(payTimer);
}
</script>



<style scoped>
#vip-pay {
  padding:20px;
}
</style>