
<template>
  <el-dialog :modelValue="loginFlag" width="400" :before-close="handleClose">
    <div class="login-title">
<!--      <div @click="setLoginType(1)" class="cursor-pointer" :class="loginType===1?'fw-600 color-main':''">扫码登录</div>-->
<!--      <el-divider direction="vertical" />-->
      <div @click="setLoginType(2)" class="cursor-pointer" :class="loginType===2?'fw-600 color-main':''">验证码登录</div>
    </div>
    <div class="login-tips">登录通言AI，获取更多AI技术服务</div>

    <div class="mt-50 login-input">
      <el-input v-model="inputPhone" style="width: 280px" placeholder="手机号" size="large"/>

      <el-input v-model="inputCode" style="width: 280px" class="mt-30" placeholder="输入验证码" size="large">
        <template #append><span class="cursor-pointer" @click="sendPhoneCode">{{inputCodeActionName}}</span></template>
      </el-input>

      <el-button :loading="loadingFlag" type="primary" style="width: 280px" class="mt-40" size="large" @click="loginAct">登录/注册</el-button>

      <div class="login-desc">
        注册登录即代表同意<a href="/agreement" target="_blank">《用户协议》</a> <a href="/private" target="_blank">《隐私政策》</a>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { defineProps, ref ,defineEmits } from 'vue';
import {apiLogin,apiSendCodeByPhone} from '@/api/user/login'
import {ElMessage} from "element-plus";

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  loginFlag: {
    type: Boolean,
    default: false,
  },
});

// const loginVisible = ;
const loginType = ref(2);

const setLoginType = (i) => {
  loginType.value = i;
}
const handleClose = () => {
  emit('update-prop', false, 0);
}
const emit = defineEmits(['update-prop']);

const inputPhone = ref("");
const inputCode = ref("")
const inputCodeActionName = ref("获取验证码");

const countdownTimerFlag = ref(false);
const loadingFlag = ref(false);
const sendPhoneCode = () => {
  if (countdownTimerFlag.value) {
    return;
  }
  countdownTimerFlag.value = true;

  if (inputPhone.value === "") {
    ElMessage.error('手机号不能为空！');
    countdownTimerFlag.value = false;
    return;
  }

  //倒计时
  let timeLeft = 60;
  let countdownTimer = setInterval(() => {
    inputCodeActionName.value = `${timeLeft}秒`;
    timeLeft--;
    if (timeLeft < 0) {
      clearInterval(countdownTimer); // 清除计时器
      countdownTimer = null;
      inputCodeActionName.value = "获取验证码"; // 清空倒计时显示
      countdownTimerFlag.value = false;
    }
  }, 1000);

  if (isValidPhoneNumber(inputPhone.value)) {
    apiSendCodeByPhone({'mobile':inputPhone.value}).then(res => {
      if (res.data.err_no != 0) {
        ElMessage.error(res.data.results);
      }
      countdownTimerFlag.value = false;
    }).catch(err => {
      console.log(err)
      countdownTimerFlag.value = false;
    })
  } else {
    ElMessage.error('手机号格式不对！')
    countdownTimerFlag.value = false;
  }
}

const  isValidPhoneNumber = (phoneNumber) => {
  // 常见中国大陆手机号的正则表达式
  const phoneRegex = /^1[3-9]\d{9}$/;
  return phoneRegex.test(phoneNumber);
}


const loginAct = () => {
  loadingFlag.value = true;
  if (inputPhone.value === "") {
    ElMessage.error('手机号不能为空！');
    loadingFlag.value = false;
    return;
  }

  if (inputCode.value === "") {
    loadingFlag.value = false;
    ElMessage.error('验证码不能为空！');
    return;
  }

  let param = {
    phone: inputPhone.value,
    code: inputCode.value,
  }
  apiLogin(param).then(res => {
    if (res.data.err_no === 0) {
      localStorage.setItem('api_token', 'Bearer ' + res.data.results.token);
      localStorage.setItem('uuid', res.data.results.user_uniq_id);
      localStorage.setItem('is_login', 1);
      emit('update-prop', false, 1);
      //页面自刷新一下
      window.location.reload();
    } else {
      ElMessage.error(res.data.err_msg);
    }

    loadingFlag.value = false;
  })
}
</script>

<style scoped>
.login-title {
  display: flex;
  margin: 0 auto;
  width:400px;
  justify-content: center;
  align-items: center;
}

.login-tips{
  color:#202020;
  font-size: 16px;
  margin-top:30px;
}

.login-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color:#8c8c8c;
  margin-top:40px;
  margin-bottom:30px;
}
.login-desc a {
  color:#8c8c8c;
}
</style>
<style>
.el-dialog {
  border-radius: 12px !important;
}
</style>