<template>
  <div id="vip-pay-status">
    <h1 class="fs-26">会员开通</h1>

    <div class="flex flex-center mt-50">
      <el-card style="width: 480px">
        <p>
          <div class="flex flex-center fs-26">
            <el-icon class="color-main"><Select /></el-icon>
            <span>会员开通成功!</span></div>
        </p>
      </el-card>

    </div>
  </div>
</template>
<script setup>
import {onMounted} from "vue";

onMounted(() => {
  countDown();
});
let timer;
const countDown = () => {
  const TIME_COUNT = 3
  if (!timer) {
    let count = TIME_COUNT
    timer = setInterval(() => {
      if (count > 1 && count <= TIME_COUNT) { //限制倒计时区间
        count--
      } else {
        clearInterval(timer)   //删除定时器
        timer = null
        window.location.href = '/';
      }
    }, 1000)
  }
}
</script>
<style scoped>

</style>