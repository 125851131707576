<template>
  <div class="logo-info">
    <router-link to="/"><span class="fs-26 fw-bold color-weight">通言AI</span></router-link>
  </div>
  <div style="padding:0 13px;">
    <div class="module-info">
      <router-link to="/chatm">
        <div class="text-left column-module">
          <el-image class="wh-14" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/%E8%AF%84%E8%AE%BA_comment.png" />
          <h3>AI对话</h3>
        </div>
      </router-link>
      <router-link to="/chat">
        <div class="text-left column-list">
          <div class=" flex flex-align-center">
            <span>历史对话</span>
            <span class="ml-5 chat-numbers">{{textChatNumbers}}</span>
          </div>
        </div>
      </router-link>
    </div>
    <div class="module-info">
      <router-link to="/aiset">
        <div class="text-left column-module">
          <el-image class="wh-14" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/%E5%BE%AA%E7%8E%AF%E7%A7%BB%E5%8A%A8_cycle-movement.png" />
          <h3>精选AI产品集</h3>
        </div>
      </router-link>
    </div>
    <div class="module-info">
      <router-link to="/es">
        <div class="text-left column-module">
          <el-image class="wh-14" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/%E4%BC%81%E4%B8%9A%E5%8F%B7_qiyehao.png" />
          <h3>企业服务</h3>
        </div>
      </router-link>
    </div>
    <div class="module-info">
      <router-link to="/bs">
        <div class="text-left column-module">
          <el-image class="wh-14" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/%E9%AD%94%E6%96%B9_cube-three.png" />
          <h3>业务板块</h3>
        </div>
      </router-link>
    </div>
    <div class="module-info">
      <router-link to="/elevator">
        <div class="text-left column-module">
          <el-image class="wh-14" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/%E9%AD%94%E6%96%B9_cube-three.png" />
          <h3>电梯板块</h3>
        </div>
      </router-link>
    </div>
<!--    <div class="module-info no-border">-->
<!--      <div class="text-left column-module">-->
<!--        <el-image class="wh-14" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/%E4%BB%AA%E8%A1%A8%E7%9B%98_dashboard.png" />-->
<!--        <h3>数据大屏</h3>-->
<!--      </div>-->
<!--      <a href="https://greentms.crrcgce.com/big/#/" target="_blank">-->
<!--        <div class="text-left column-list color-weight"><span>智慧车运</span></div>-->
<!--      </a>-->
<!--      <a href="https://jg.senyuanyuan.com/admin/#/login?source=Y3JlZW5CaWc%3D&secret=Y3JlZW5CaWcxMjM0" target="_blank">-->
<!--        <div class="text-left column-list color-weight"><span>智慧垃圾分类</span></div>-->
<!--      </a>-->
<!--      <a href="https://www.wanjunshijie.com/demo/shujudaping/guangfuzhineng/vue3/#/" target="_blank">-->
<!--        <div class="text-left column-list color-weight"><span>智慧光伏</span></div>-->
<!--      </a>-->
<!--      <a href="https://cold-chain.crrcgce.com/#/pages/fullscreen/cold-box-screen?tokens=90cc4e3dc487ac2cf97e50bc998d67246ee1f6af15769d0e95f1087009528b59" target="_blank">-->
<!--        <div class="text-left column-list color-weight"><span>智慧冷链集配</span></div>-->
<!--      </a>-->
<!--      <a href="https://cold-chain.crrcgce.com/#/pages/fullscreen/cold-machine-screen?tokens=ffbeb0b6cb9018c8e6b85872ca815fcf645b8f01a8be6a4f667fdad767e06cc1" target="_blank">-->
<!--        <div class="text-left column-list color-weight"><span>智慧冷链充冷</span></div>-->
<!--      </a>-->
<!--    </div>-->
  </div>
  <div class="fixed-bottom" style="">
    <div class="login-info cursor-pointer" @click="showLogin" v-show="loginStatus!=1">
      <el-icon><User /></el-icon>
      <span class="ml-10">登录</span>
    </div>
    <el-popover placement="top" :width="160">
      <div>
        <div class="flex flex-align-center fs-12">
          <el-icon><Avatar /></el-icon>
          <span class="ml-5">{{userInfo.userName}}</span>
        </div>
        <el-divider style="margin:10px 0px;"/>
        <div class="fs-12">
          <router-link to="/vip-order">
            <div class="user-list-item flex flex-align-center">
              <el-icon><Tickets /></el-icon><span class="ml-5">订单信息</span>
            </div>
          </router-link>
<!--          <div class="user-list-item flex flex-align-center">-->
<!--            <el-icon><Medal /></el-icon><span class="ml-5">会员信息</span>-->
<!--          </div>-->
        </div>
      </div>
      <template #reference>
        <div class="login-info cursor-pointer fs-12" v-show="loginStatus==1">
          <el-icon><Avatar /></el-icon>
          <span class="ml-5">{{userInfo.userName}}</span>
        </div>
      </template>
    </el-popover>
    <div class="mt-10" v-show="loginStatus==1">
      <router-link to="/vip-pay" v-show="userInfo.userLevel == 0">
        <el-button type="success" icon="Medal" color="#444" class="vip-pay">开通会员</el-button>
      </router-link>
<!--      <router-link to="/vip-pay" v-show="userInfo.userLevel == 1">-->
        <el-button type="success" icon="Medal" color="#444" class="vip-pay" v-show="userInfo.userLevel == 1">6小时卡会员</el-button>
<!--      </router-link>-->
      <el-button type="success" icon="Medal" color="#444" class="vip-pay" v-show="userInfo.userLevel == 2">日卡会员</el-button>
      <el-button type="success" icon="Medal" color="#444" class="vip-pay" v-show="userInfo.userLevel == 3">周卡卡会员</el-button>
      <el-button type="success" icon="Medal" color="#444" class="vip-pay" v-show="userInfo.userLevel == 4">月卡会员</el-button>
      <div class="mt-10 text-left">
        <router-link to="/vip-pay" v-show="userInfo.userLevel != 0">
          <el-button size="small" type="success"  color="#444" class="vip-continue" v-show="userInfo.userLevel == 1">续费/升级</el-button>
        </router-link>
      </div>

    </div>
  </div>

  <Login :loginFlag="loginFlag" @update-prop="updateLoginFlag" />
</template>

<script>
import Login from './Login.vue'
import {ref, onMounted} from "vue";
import {apiGetUserInfo} from '@/api/user/login'
import {apiGetUserChatNumbers} from '@/api/chat/chat'
import {apiGetUserLevel} from "@/api/user/user";


export default {
  components: { Login },
  setup() {
    const textChatNumbers = ref(0)
    const loginStatus = ref(localStorage.getItem('is_login'));
    const userInfo = ref({
      userName: '',
      userLevel: 0,//0表示试用用户 1表示6小时用户
    });
    const getUserInfo = () => {
      apiGetUserInfo().then(res => {
        if (res.data.err_no === 0) {
          let data = res.data.results;
          localStorage.setItem('user_info', JSON.stringify(data));
          userInfo.value.userName = data.phone;
        } else {
          localStorage.setItem('is_login', 0);
          loginStatus.value = 0;
        }
      })
    }

    //获取用户当前有效对话数
    const getUserChatNumbers = () => {
      apiGetUserChatNumbers().then(res => {
        if (res.data.err_no === 0) {
          let data = res.data.results;
          textChatNumbers.value = data.textChatNumbers;
        }
      })
    }

    const getUserLevel = () => {

      apiGetUserLevel().then(res => {
        if (res.data.err_no == 0) {
          localStorage.setItem('user_level', 0);
          if (res.data.results.account_type !== undefined) {
            userInfo.value.userLevel = res.data.results.account_type;
            localStorage.setItem('user_level', userInfo.value.userLevel);
          }
        }
      })
    }

    const initOperation = () => {
      getUserChatNumbers();
      getUserInfo();
      getUserLevel();
    }

    onMounted(() => {
      if (parseInt(loginStatus.value) === 1) {
        initOperation();
      }
    });

    const loginFlag = ref(false);
    const showLogin = () => {
      loginFlag.value = true;
    }

    function updateLoginFlag(newValue, isLogin) {
      loginFlag.value = newValue;
      if (parseInt(isLogin) === 1) {
        loginStatus.value = 1;
        initOperation();
      }
    }

    return {
      loginStatus,
      loginFlag,
      userInfo,
      textChatNumbers,
      showLogin,
      updateLoginFlag
    }
  }
}
</script>

<style>
.login-info {
  display: flex;
  align-items: center;
  font-size:14px;
  padding:8px 4px;
}
.login-info:hover {
  cursor: pointer;
  border-radius: 4px;
  background-color:#2b2d31;
}

.logo-info {
  height:36px;
  margin:0 20px;
  padding:8px;
  border-bottom: 1px solid #2b2d31;
}
.user-list-item {
  padding:6px 4px;
  color:#606266;
}
.user-list-item:hover {
  cursor: pointer;
  border-radius: 4px;
  background-color:#ececec;
}
.module-info {
  border-bottom: 1px solid #2b2d31;
}
.column-module {
  border-radius: 6px;
  padding:0px 8px;
  display:flex;
  align-items: center;
  color:#fff;
}
.column-module h3 {
  margin-left:10px;
  font-weight: 600;
  font-size: 14px;
}
.column-module:hover {
  cursor: pointer;
  background-color:#2b2d31;
}
.column-list {
  border-radius: 6px;
  padding:10px 8px;
  display:flex;
  align-items: center;
  color:#fff;
}
.column-list>div {
  margin-left:24px;
  font-size: 13px;
}
.column-list:hover {
  background-color:#2b2d31;
  cursor: pointer;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width:120px;
  margin:10px;
  padding:20px;
  border-top:1px solid #2b2d31;
  background-color:#000;
}

.chat-numbers {
  height: 18px;
  line-height: 18px;
  background: rgba(217, 217, 217, .18);
  color: #d9d9d9;
  border-radius: 9px;
  padding: 0 2px;
  min-width: 18px;
  text-align: center;
  margin-left: 12px;
  font-size: 12px;
}

.vip-pay {
  width:100%;
  color:#f6ca9d !important;
  font-size:12px;
}

.vip-continue {
  width:50%;
  color:#f8f8f9 !important;
  font-size:10px;
}
</style>