
<template>
  <el-aside width="260px" class="chat-list">
    <div style="height:20px;padding:20px 0px;">
      <router-link to="/chatm">
        <el-button size="large" type="primary" style="width: 80%">
          <el-icon><Plus /></el-icon>
          <span class="ml-5">创建新对话</span>
        </el-button>
      </router-link>
    </div>
    <div style="padding:0 13px;">
      <div v-show="pOne.data !== undefined &&pOne.data.length>0">
        <div class="text-left" style="padding:0px 8px;">
          <h3 class="chat-date">{{ pOne.title }}</h3>
        </div>
        <div :class="['chat-title', selectedSessionId==item.session_id?'chat-title-selected':'']" v-for="item in pOne.data" @click="selectChatTitle(item.session_id, item.engine_type)">
          <div class="chat-title-content" >{{item.talk_title}}</div>
          <el-popover placement="bottom"  trigger="hover" >
            <template #reference>
              <el-icon class="chat-operation"><MoreFilled /></el-icon>
            </template>
            <template #default>
              <ul style="text-align: center;">
                <li class="flex flex-center title-operate-li fs-12" @click="deleteChatInfo(item.session_id)">
                  <el-icon><Delete /></el-icon>
                  <span class="ml-10">删除</span>
                </li>
              </ul>
            </template>
          </el-popover>
        </div>
      </div>

      <div v-show="pTwo.data !== undefined &&pTwo.data.length>0">
        <div class="text-left" style="padding:0px 8px;">
          <h3 class="chat-date">{{ pTwo.title }}</h3>
        </div>
        <div @click="selectChatTitle(item.session_id, item.engine_type)" :class="['chat-title', selectedSessionId==item.session_id?'chat-title-selected':'']" v-for="item in pTwo.data" >
          <div class="chat-title-content" >{{item.talk_title}}</div>
          <el-popover placement="bottom"  trigger="hover" >
            <template #reference>
              <el-icon class="chat-operation"><MoreFilled /></el-icon>
            </template>
            <template #default>
              <ul style="text-align: center;">
                <li class="flex flex-center title-operate-li fs-12" @click="deleteChatInfo(item.session_id)">
                  <el-icon><Delete /></el-icon>
                  <span class="ml-10">删除</span>
                </li>
              </ul>
            </template>
          </el-popover>
        </div>
      </div>

      <div v-show="pThree.data !== undefined &&pThree.data.length>0">
        <div class="text-left" style="padding:0px 8px;">
          <h3 class="chat-date">{{ pThree.title }}</h3>
        </div>
        <div @click="selectChatTitle(item.session_id, item.engine_type)" :class="['chat-title', selectedSessionId==item.session_id?'chat-title-selected':'']" v-for="item in pThree.data" >
          <div class="chat-title-content" >{{item.talk_title}}</div>
          <el-popover placement="bottom"  trigger="hover" >
            <template #reference>
              <el-icon class="chat-operation"><MoreFilled /></el-icon>
            </template>
            <template #default>
              <ul style="text-align: center;">
                <li class="flex flex-center title-operate-li fs-12" @click="deleteChatInfo(item.session_id)">
                  <el-icon><Delete /></el-icon>
                  <span class="ml-10">删除</span>
                </li>
              </ul>
            </template>
          </el-popover>
        </div>
      </div>
    </div>
  </el-aside>

  <el-dialog v-model="deleteChatDialogVisible" title="对话删除操作" width="400" >
    <div style="margin:20px 0px;" class="flex flex-center">
      <el-icon class="color-danger"><WarningFilled /></el-icon>
      <span class="ml-10">确定要删除该对话吗</span>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="deleteChatDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmDeleteChatInfo">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import {apiGetUserTextChatList,apiDeleteChatInfo} from "@/api/chat/chat";
import {onMounted, ref,defineProps, defineEmits} from "vue";
import {ElMessage} from "element-plus";

// 定义 props 接收父组件传递的值
const props = defineProps({
  sessionId: String,
});

const selectedSessionId = ref(props.sessionId)
const pOne = ref({})
const pTwo = ref({})
const pThree = ref({})
const deleteSessionId = ref("")
const deleteChatDialogVisible = ref(false)
onMounted(() => {
  getUserTextChatList();
});

const getUserTextChatList = () => {
  apiGetUserTextChatList().then(res => {
    if (res.data.err_no === 0) {
      let data = res.data.results;
      pOne.value = data.pOne
      pTwo.value = data.pTwo
      pThree.value = data.pThree

      if (selectedSessionId.value === "") {
        const current_text_session_key = "current_text_session_id";
        selectedSessionId.value = localStorage.getItem(current_text_session_key);

        if (selectedSessionId.value === null) {
          if (data.pOne.data.length > 0) {
            selectChatTitle(data.pOne.data[0].session_id, data.pOne.data[0].engine_type)
          } else if (data.pTwo.data.length > 0) {
            selectChatTitle(data.pTwo.data[0].session_id, data.pTwo.data[0].engine_type)
          } else if (data.pThree.data.length > 0) {
            selectChatTitle(data.pThree.data[0].session_id, data.pThree.data[0].engine_type)
          }
        } else {
          for (let i=0; i<data.pOne.data.length; i++) {
            if (data.pOne.data[i].session_id == selectedSessionId.value) {
              selectChatTitle(data.pOne.data[i].session_id, data.pOne.data[i].engine_type);
            }
          }
          for (let i=0; i<data.pTwo.data.length; i++) {
            if (data.pTwo.data[i].session_id == selectedSessionId.value) {
              selectChatTitle(data.pTwo.data[i].session_id, data.pTwo.data[i].engine_type);
            }
          }
          for (let i=0; i<data.pThree.data.length; i++) {
            if (data.pThree.data[i].session_id == selectedSessionId.value) {
              selectChatTitle(data.pThree.data[i].session_id, data.pThree.data[i].engine_type);
            }
          }
        }
      }
    }
  });
}

// 定义 emit 函数
const emit = defineEmits(['update-session-id']);


const selectChatTitle = (sessionId, aiType) => {
  selectedSessionId.value = sessionId;

  let data = {
    ai_type: aiType,
    session_id: selectedSessionId.value
  }
  emit('update-session-info', data);
}

const deleteChatInfo = (sessionId) => {
  deleteSessionId.value = sessionId;
  deleteChatDialogVisible.value = true;
}

const confirmDeleteChatInfo = () => {
  apiDeleteChatInfo({session_id: deleteSessionId.value}).then(res => {
    if (res.data.err_no == 0) {
      deleteSessionId.value = "";
      deleteChatDialogVisible.value = false;
      ElMessage({
        message: '操作成功！',
        type: 'success',
      })
      getUserTextChatList();
    } else {
      ElMessage({
        showClose: true,
        message: '操作失败了！',
        type: 'error',
      })
    }
  })
}

</script>
<style scoped>
.chat-list {
  border-right: 1px solid #dcdfe6;
}
.chat-date {
  color:#7d7d7d;
  font-weight: 600;
  font-size: .75rem;
  line-height: 1rem;
}
.chat-title {
  position: relative;
  text-align:left;
  color:#0d0d0d;
  font-size:12px;
  padding: 12px;
  cursor: pointer;
}

.chat-title:hover {
  background-color:#ececec;
  border-radius:6px;
}

.chat-operation {
  display: none;
  color:#909399;
  position: absolute; /* 绝对定位 */
  right: 10px;
  top:13px;
}

.chat-title:hover .chat-operation {
  display: block; /* 鼠标悬停时显示 */
}

.chat-title-content {
  width:88%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-title-selected {
  border-radius:6px;
  background-color:#ececec;
}
.title-operate-li {
  padding:6px 0px;
}
.title-operate-li:hover {
  pointer-events: auto;
  background-color:#ececec;
  border-radius:6px;
  cursor: pointer;
}

ul, ol {
  list-style: none; /* 移除列表样式 */
  padding: 0;       /* 可选：移除内边距 */
  margin: 0;        /* 可选：移除外边距 */
}
</style>
<style>
.el-dialog__header {
  text-align: left;
}
</style>