import request from '@/plugins/http'

export function apiLogin(param) {
    return request.post('/api/user/login', param);
}

export function apiGetUserInfo() {
    return request.get('/api/user/userInfo');
}

export function apiSendCodeByPhone(param) {
    return request.post('/api/user/sendCodeByPhone', param);
}






