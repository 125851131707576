import request from '@/plugins/http'

export function apiGetVipPay() {
    return request.get('/api/account/getVipPay');
}

export function apiGetUserVipPayCode(param) {
    return request.post('/api/account/getUserVipPayCode', param);
}

export function apiCheckPayStatus(param) {
    return request.post('/api/account/checkPayStatus', param);
}

export function apiGetVipOrderList() {
    return request.post('/api/account/getVipOrderList');
}



