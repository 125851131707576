import request from '@/plugins/http'

export function apiDallE3(param) {
    return request.post('/aiApi/dalle3', param);
}


export function apiGetUserChatNumbers() {
    return request.get('/api/talk/getUserChatNumbers');
}

export function apiGetUserTextChatList() {
    return request.get('/api/talk/getUserTextChatList');
}

export function apiGetTalkRecord(param) {
    return request.get('/api/talk/getTalkRecord', param);
}

export function apiSetTalkRecord(param) {
    return request.post('/api/talk/setTalkRecord', param);
}

export function apiCreateTalkRecord(param) {
    return request.post('/api/talk/createTalkRecord', param);
}

export function apiDeleteChatInfo(param) {
    return request.post('/api/talk/deleteTalkRecord', param);
}












